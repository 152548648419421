import React, { FunctionComponent } from "react";
import classNames from "classnames";
import { Container, Row, Col } from "react-bootstrap";
import "./styles.scss";

interface SectionProps {
  title?: string;
  description?: string;
  theme?: string;
  sectionClassName?: string;
  style?: any;
}

const Section: FunctionComponent<React.PropsWithChildren<SectionProps>> = ({
  children,
  title,
  theme,
  description,
  sectionClassName = "",
  style
}) => {
  return (
    <section
      className={classNames("c-section", theme && `rx-theme--${theme.toLocaleLowerCase()}`, sectionClassName)}
      style={style}
    >
      <Container fluid>
        <Row>
          <Col sm={12}>
            {title ? (
              <header>
                <h2 className="c-section__title">{title}</h2>
                {description ? <p className="c-section__description">{description}</p> : null}
              </header>
            ) : null}
            {children}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Section;
