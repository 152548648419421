import React, { FunctionComponent } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import "./styles.scss";
import Section from "../Section";
import RichText from "../RichText";
import { ImageInterface } from "web/common/src/types/SanityTypes";
import { Col, Container, Row } from "react-bootstrap";

interface TwoColumnsStickyScrollInterface {
  palleteColorList?: {
    bgColor: {
      value: string;
      title: string;
    };
  };
  _rawDescription: any;
  ctaLink: any;
  title: string;
  image?: ImageInterface;
}

const TwoColumnsStickyScroll: FunctionComponent<TwoColumnsStickyScrollInterface> = (props: any) => {
  
  const { palleteColorList, _rawDescription, ctaLink, title, image } = props;
  return (
    <Section sectionClassName="c-bluePrint-applynow" theme={palleteColorList?.bgColor.title}>
      <Container fluid>
        <Row>
          <Col xs={12} md={5}>
            <div className="is-sticky">
              <h2 className="c-bluePrint-applynow__title">{title}</h2>
              {ctaLink?.ctaLabel && (
                <div className="c-bluePrint-applynow__cta">
                  {/* <CtaBlock ctaBlock={ctaLink} ctaLabel={ctaLink.ctaLabel} /> */}
                </div>
              )}
              <div className="c-bluePrint-applynow__image">
                {image.asset && <SanityImage
                  {...image}
                  sizes="(min-width: 475px) 553px"
                  width={553}
                  height={513}
                  className={`c-bluePrint-applynow__image-img`}
                />}
              </div>
            </div>
          </Col>

          <Col xs={12} md={7}>
            <RichText data={_rawDescription} />
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default TwoColumnsStickyScroll;
